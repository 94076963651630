<template>
  <v-card flat class="mt-5">
    <div class="pa-3">
      <v-card-text>
        <v-row>
          <v-col
            :cols="12"
            class="align-self-start"
            v-for="(contract) in contracts"
            :key="contract.id"
          >
            <v-card class="overflow-hidden" outlined>
              <v-row class="ma-0 h-full">
                <v-col cols="12" sm="8" md="12" lg="8" order="2" order-lg="1" class="pa-0 align-self-center">
                  <v-card-title>
                    <div>
                      Nº: {{ contract.id }}
                      <v-chip
                        v-if="!contract.active"
                        small
                        color="error"
                        outlined
                        label
                        class="font-weight-medium ml-2"
                      >
                        Inativo
                      </v-chip>
                      <v-chip
                        v-else-if="isAfter(contract.endDate)"
                        small
                        outlined
                        label
                        class="font-weight-medium ml-2"
                      >
                        Vencido
                      </v-chip>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    O LOCATÁRIO declara ter pleno conhecimento de que nos imóveis, objetos deste instrumento, existiam
                    construções dos quais foram demolidas e que tramita junto a Prefeitura Municipal de Londrina
                    processo de regularização referente a demolição ora realizada nos referidos imóveis, dando o seu
                    inequívoco ACEITE no que tange as condições existentes.
                  </v-card-text>
                  <v-card-text>
                    <v-divider></v-divider>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <div class="me-auto pe-4">
                      <p class="d-flex align-center mb-4">
                        <v-icon color="primary">mdi-calendar-check-outline</v-icon>
                        <span class="ms-3">
                          Pagamento todo dia: <b>{{ contract.payday }}</b>
                        </span>
                      </p>
                      <p class="d-flex align-center mb-0">
                        <v-icon color="primary">mdi-calendar-range-outline</v-icon>
                        <span class="ms-3">
                          Iniciado em: <b>{{ contract.startDate | date }}</b></span
                        >
                      </p>
                    </div>

                    <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical inset> </v-divider>

                    <div class="ms-auto ps-4">
                      <p class="d-flex align-center mb-4">
                        <v-icon color="primary">mdi-calendar-clock-outline</v-icon>
                        <span class="ms-3">
                          Duração: <b>{{ calcDuration(contract) }}</b> meses
                        </span>
                      </p>
                      <p class="d-flex align-center mb-0">
                        <v-icon color="primary">mdi-file-document-outline</v-icon>
                        <span class="ms-3">
                          IPTU: <b>{{ contract.iptuInclude ? "Locadora" : "Locatário" }}</b>
                        </span>
                      </p>
                    </div>
                  </v-card-actions>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="12"
                  lg="4"
                  order="1"
                  order-lg="2"
                  class="memberpricing-bg align-self-center"
                >
                  <div class="membership-pricing py-md-10 py-4">
                    <p class="text--primary">
                      <sub class="text-2xl">R$</sub>
                      <sup class="font-weight-semibold">{{ contract.value | number }}</sup>
                      <sub class="text-xl">,{{ contract.value | numberDecimals }}</sub>
                    </p>
                    <p class="my-4 text--secondary text-xs">(Mensalidade sem considerar descontos)</p>
                    <v-btn
                      :disabled="!contract.url"
                      @click="openBlank(contract.url)"
                      color="primary"
                      class="mt-md-10 mt-2"
                      :outlined="!contract.url"
                    >
                      Ver Contrato
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {};
  },
  methods: {
    async fetchContract() {
      this.contract = await this.$store.dispatch("locations/getContract", this.$route.params.id);
    },
    calcDuration(contract) {
      return moment(contract.endDate).diff(moment(contract.startDate), "months");
    },
    openBlank(url) {
      window.open(url, "_blank");
    },
    isAfter(date) {
      return moment().isAfter(date);
    }
  },
  computed: {
    contracts() {
      return this.rental.Contracts.sort((a, b) => a.createdAt > b.createdAt);
    }
  },
  filters: {
    date(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    number(value) {
      value = parseFloat(value);
      return value.toFixed(2).split(".")[0];
    },
    numberDecimals(value) {
      value = parseFloat(value);
      return value.toFixed(2).split(".")[1];
    }
  },
  props: {
    rental: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/plugins/vuetify/default-preset/preset/mixins.scss";
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, "primary-shade"), map-deep-get($material, "states", "hover"));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3rem;
    top: 9px;
  }
}
</style>
