<template>

  <v-card id="user-profile-card" :loading="loading">
    <!-- tabs -->
    <v-tabs v-model="tab" class="mt-1" show-arrows>
      <v-tab v-for="tab in loading ? [] : tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <detail :rental="rental"></detail>
      </v-tab-item>

      <v-tab-item>
        <payment :rental="rental"></payment>
      </v-tab-item>

      <v-tab-item>
        <contract :rental="rental"></contract>
      </v-tab-item>

      <v-tab-item>
        <attachment :rental="rental"></attachment>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import detail from "./Detail.vue";
import payment from "./Payment.vue";
import contract from "./Contract.vue";
import attachment from "./Attachment.vue";
import rentalService from "@/services/rental";

export default {
  data: () => ({
    rental: {},
    tab: "",
    tabs: [
      { title: "Detalhes", icon: "mdi-text-box-check-outline" },
      { title: "Pagamento(s)", icon: "mdi-cash-multiple", id: "payments" },
      { title: "Contrato(s)", icon: "mdi-file-document-multiple-outline", id: "contracts" },
      { title: "Anexo(s)", icon: "mdi-paperclip", id: "attachments" }
    ],
    loading: false
  }),

  methods: {
    async getRental() {
      try {
        let { id } = this.$route.params;
        let rental = await rentalService.getOneById(id);
        this.rental = rental.rental;
        this.loading = false;
        this.selectTab();
      } catch (e) {
        this.$toast
          .fire({
            title: e.message,
            icon: "error"
          })
          .then(() => {
            this.$router.push("/dashboard");
          });
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace("#", "");
        let index = this.tabs.findIndex(tab => tab.id == hash);
        if (index) this.tab = index;
      }
    }
  },

  async mounted() {
    this.loading = true;
    await this.getRental();
    this.$root.$on("payment-notification", () => {
      this.selectTab();
    });
  },

  components: {
    detail,
    payment,
    contract,
    attachment
  }
};
</script>
