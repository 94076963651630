<template>
  <v-card flat class="mt-5">
    <div class="pa-3">
      <v-card-text v-if="rental">
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="align-self-start"
            v-for="(attachment, index) in rental.Attachments"
            :key="attachment.id"
          >
            <v-card color="text-center" outlined>
              <div class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row">
                <div class="mx-auto my-3 align-self-center">
                  <v-icon x-large class="d-block">mdi-file-pdf-box</v-icon>
                  <span class="text-sm">{{ attachment.size | fileSize }}</span>
                </div>
                <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
                <div class="align-self-center">
                  <p class="my-3 font-weight-bold">{{ attachment.name }}</p>
                  <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="goToURL(index)" color="primary">Baixar Arquivo</v-btn>
                  </v-card-actions>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  methods: {
    goToURL(e) {
      window.open(this.rental.Attachments[0].url, "_blank");
    }
  },
  filters: {
    fileSize(bytes) {
      let si = false,
        dp = 1;
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }

      const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + " " + units[u];
    }
  },
  props: {
    rental: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/plugins/vuetify/default-preset/preset/mixins.scss";
</style>
